<template>
    <div class="fixed z-50 inset-0 overflow-y-hidden">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">  

            <div class="fixed inset-0 transition-opacity" aria-hidden="true">
                <div @click.self="closeModal" class="absolute inset-0 bg-gray-600 opacity-75"></div>
            </div>

            <!-- This element is to trick the browser into centering the modal contents. -->
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            
            <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                    <button @click="closeModal" type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <span class="sr-only">Close</span>
                            <!-- Heroicon name: outline/x -->
                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div class="modal text-left">
                    <slot name="header">
                        <h2 class="uppercase text-xl font-extrabold">
                            Standaard titel
                        </h2>
                    </slot>
                    <slot name="body">
                        <div class="mt-2">
                            <p class="pb-6">
                                Standaard content
                            </p>
                        </div>
                    </slot>
                    <slot name="footer">
                        <div class="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                            <button type="button" class="button w-full inline-flex justify-center sm:ml-3 sm:w-auto ">
                                Opslaan
                            </button>
                            <button @click="closeModal" type="button" class="button-black w-full inline-flex justify-center mt-3 sm:mt-0 sm:w-auto ">
                                Annuleren
                            </button>
                        </div>
                    </slot>
                </div>
            </div>
        </div>
    </div>  
</template>

<script>
export default {
    name: 'Modal',
    methods: {
        closeModal() {
            this.$emit('closed');
        },
    },
};
</script>